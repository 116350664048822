/** @jsxImportSource react */
import ProductSmall from '@components/ProductSmall'
import FontAwesomeIcon from '@components/FontAwesomeIcon'
import algoliasearch, { SearchClient } from 'algoliasearch/lite'
import { InstantSearch, SearchBox, Hits, Configure, useInstantSearch } from 'react-instantsearch'
import { useCallback, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { debounce } from '$/utils/debounce'
import type { Hit as AlgoliaHit } from 'instantsearch.js'
import miniCartUpsellList from '$/data/upsell-list.json'
import './search.css'

const featuredProducts = miniCartUpsellList.slice(0, 6)
const algoliaClient = algoliasearch('R0WJYZ49C8', 'baebfc811d50a793b9d811ba64016994')

const searchClient = {
  ...algoliaClient,
  // disables initial network request with empty query
  search(requests) {
    if (requests.every(({ params }) => params!.query === '')) {
      return Promise.resolve({
        results: [
          {
            __isArtificial: true,
            nbHits: 0,
            hitsPerPage: 12,
            hits: [],
          },
        ],
      })
    }

    return algoliaClient.search(requests)
  },
} as SearchClient

const IS_PROD = import.meta.env.PUBLIC_ENVIRONMENT === 'production'

export default function Search() {
  const [isOpen, setIsOpen] = useState(false)
  const searchButtonRef = useRef(document.getElementById('search-button'))
  const searchContainerRef = useRef(null)
  const searchBackdropRef = useRef(null)

  const onOpen = useCallback(() => {
    setIsOpen(true)
    document.documentElement.setAttribute('data-modal', 'open')
  }, [setIsOpen])

  const onClose = useCallback(() => {
    setIsOpen(false)
    document.documentElement.removeAttribute('data-modal')
  }, [setIsOpen])

  const queryHook = debounce((query: string, search: (value: string) => void) => {
    if (query === '') {
      return
    }

    search(query)
  })

  useEffect(() => {
    searchButtonRef.current?.addEventListener('click', onOpen)
    return () => searchButtonRef.current?.removeEventListener('click', onOpen)
  }, [searchButtonRef.current, onOpen])

  if (!isOpen) {
    document.documentElement.removeAttribute('data-modal')
    return null
  }

  return createPortal(
    <>
      <div
        ref={searchBackdropRef}
        className="u-fixed u-left-0 u-top-0 u-z-[1999] u-h-full u-w-full u-bg-[rgba(0,0,0,0.4)]"
        onClick={onClose}
      />
      <div
        ref={searchContainerRef}
        className="u-fixed u-left-0 u-top-0 u-z-[2000] u-h-full u-w-full u-bg-white u-py-8 tablet:u-h-auto"
      >
        <InstantSearch
          indexName={IS_PROD ? 'products' : 'products_stg'}
          searchClient={searchClient}
          stalledSearchDelay={250}
        >
          <Configure hitsPerPage={12} />
          <div className="u-border-b u-pb-4">
            <div className="container">
              <div className="u-flex u-w-full u-gap-4">
                <SearchBox
                  queryHook={queryHook}
                  placeholder="What are you looking for?"
                  autoFocus
                  submitIconComponent={() => <FontAwesomeIcon icon="magnifying-glass" />}
                />
                <button onClick={onClose} title="Close Search">
                  <FontAwesomeIcon icon="xmark" />
                </button>
              </div>
            </div>
          </div>
          <div className="container u-max-h-screen u-pt-4">
            <ResultsCount />
            <NoResultsBoundary fallback={<NoResults />} initial={<InitialResults />}>
              <Hits hitComponent={Hit} />
            </NoResultsBoundary>
          </div>
        </InstantSearch>
      </div>
    </>,
    document.body,
  )
}

function ResultsCount() {
  const { results } = useInstantSearch()

  if (results.__isArtificial) {
    return null
  }

  return <div className="u-mb-4 u-font-medium">{Math.min(results.nbHits, results.hitsPerPage)} Results</div>
}

function NoResultsBoundary({
  children,
  fallback,
  initial,
}: {
  children: React.ReactNode
  fallback: React.ReactNode
  initial: React.ReactNode
}) {
  const { results } = useInstantSearch()

  if (results.__isArtificial) return <>{initial}</>
  else if (!results.__isArtificial && results.nbHits === 0) return <>{fallback}</>

  return <>{children}</>
}

function InitialResults() {
  return (
    <div className="l-stack">
      <b className="p4">Best Sellers</b>
      <div>
        <ul
          role="list"
          className="u-grid u-grid-cols-2 u-gap-[15px] tablet:u-grid-cols-3 desktop:u-grid-cols-6 desktop:u-gap-[30px]"
        >
          {featuredProducts.map((product) => (
            <li key={product.id}>
              <ProductSmall product={product} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

function NoResults() {
  return (
    <div>
      <p>No results.</p>
    </div>
  )
}

type HitType = AlgoliaHit<{
  id: number
  categories: string[]
  listingImage: string
  name: string
  slug: string
  type: string
}>

function Hit({ hit }: { hit: HitType }) {
  const product = {
    categories: hit.categories,
    id: hit.id,
    listingImage: hit.listingImage,
    name: hit.name,
    slug: hit.slug,
  }

  return <ProductSmall product={product} />
}
